import { SC } from "../ServerCall";
import { getOrgPermissions } from "./../endPoints";

export const hasPermissions = () => {
  let userData = JSON.parse(localStorage.getItem("User_Data"));
  let token = localStorage.getItem("userToken");
  if (token && userData?.userPermissions) {
    let permissionName = userData.userPermissions?.map((item) => item.name);
    return permissionName;
  } else {
    return [];
  }
};

export const checkPermission = (permission) => {
  if (hasPermissions()?.filter((item) => item === permission)?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getRole = () => {
  let userData = JSON.parse(localStorage.getItem("User_Data"));
  return userData?.role[0] || "";
};

export const changeLocalStorageOrg = (Id) => {
  SC.postCall(getOrgPermissions, {
    organization_id: Id,
  }).then((res) => {
    if (res.status === 200 && res.data) {
      let orgPermissions = res.data?.data?.permissions;

      let localData = localStorage.getItem("User_Data");
      localData = JSON.parse(localData);
      localData.types.filter((item) => {
        if ((item.organization_id = Id)) {
          localData.role = [item.alias_name];
        }
      });
      localData.userPermissions = orgPermissions;

      localStorage.setItem("User_Data", JSON.stringify(localData));
    }
  });
  window.location.reload(true);
  return true;
};
