import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Fragment } from "react";
import Dropzone from "react-dropzone";
import { SC } from "../ServerCall";
import Spinner from "reactstrap/lib/Spinner";
import { TiDelete } from "react-icons/ti";
import PDF from "@src/assets/images/icons/file-icons/pdf.svg";
import Excel from "@src/assets/images/icons/file-icons/xlsx.svg";
import ppt from "@src/assets/images/icons/file-icons/Ppt.svg";

import Image from "@src/assets/images/icons/file-icons/jpg.svg";
import CSV from "@src/assets/images/icons/file-icons/doc.svg";
import { FormattedMessage } from "react-intl";
import DragNDrop from "@src/assets/images/icons/dragndrop.svg";
import file from "@src/assets/images/icons/file-icons/File.svg";
// import FilePreview from "./FilePreview";

function FileUploader(props) {
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    if (props.call !== "createTickets" && props.Data?.attachment?.length > 0) {
      setAttachment(props.Data?.attachment?.map((file) => file));
    }

    if (props.postComment) {
      setAttachment([]);
      props.setPostComment(false);
    }
  }, [props, props.postComment]);
  const convertToBase64 = async (files) => {
    if (files?.length > 0) S3call(files[0]);

  };
  //S3 upload attachment
  const S3call = async (data) => {
    let endpoint = "upload";
    const formData = {};
    Object.assign(formData, {
      "file": data,
      "project": process.env.REACT_APP_PROJECT
    })

    // formData.app end("project", process.env.REACT_APP_PROJECT);
    if (attachment?.length <= 9) {
      return SC.postAttachment(
        process.env.REACT_APP_ATTACHMENT_URL + endpoint,
        formData
      ).then(
        (res) => {
          if (res.status === 200 && res.data) {
            let file = res.data.paths;
            setLoading(false);
            if (typeof file === "string") {
              setAttachment([...attachment, file]);
              // toast.success("Image uploaded successfully!");
              props.handleChange("attachment", [...attachment, file]);
            } else {
              setAttachment([...attachment, ...file]);
              // toast.success("Image uploaded successfully!");
              props.handleChange("attachment", [...attachment, ...file]);
            }
          }
          return res.data.paths;
        },
        (error) => {
          setLoading(false);
          if (error?.response?.data?.errors) {
            toast.error(error?.response?.data?.errors);
          } else {
            toast.success("Something went wrong!");
          }
          return false;
        }
      );
    } else {
      toast.success("You submit max-10 files!");
      setLoading(false);
    }
  };
  //delete files
  const handleDelete = (id) => {
    const filteredFiles = attachment?.filter((file, index) => index !== id);
    const filteredName = detail?.filter((file, index) => index !== id);
    setDetail(filteredName);
    setAttachment(filteredFiles);
    props.handleChange("attachment", filteredFiles);
  };
  const thumbs = attachment?.map((src, index) => {
    let ext = src?.split(".").pop();
    let filename = src?.substring(src.lastIndexOf("/") + 1);

    if (
      ext === "csv" ||
      ext === "pdf" ||
      ext === "sheet" ||
      ext === "xlsx" ||
      ext === "xls" ||
      ext === "xlt" ||
      ext === "xla" ||
      ext === "xltx" ||
      ext === "xlsm" ||
      ext === "xltm" ||
      ext === "xlam" ||
      ext === "xlsb" ||
      ext === "ms-excel" ||
      ext === "document" ||
      ext === "docx" ||
      ext === "doc" ||
      ext === "dot" ||
      ext === "dotx" ||
      ext === "docm" ||
      ext === "dotm" ||
      ext === "ppt" ||
      ext === "pptx" ||
      ext === "pot" ||
      ext === "pps" ||
      ext === "ppa" ||
      ext === "potx" ||
      ext === "ppsx" ||
      ext === "ppam" ||
      ext === "pptm" ||
      ext === "potm" ||
      ext === "ppsm" ||
      ext === "mdb" ||
      ext === "presentation"
    ) {
      return (
        <div
          className="flex-column d-flex mt-1 ml-1 mr-1  text-nowrap"
          style={{ height: 80, width: 70 }}
          key={index}
        >
          <div className="d-flex justify-content-center">
            <TiDelete
              size={20}
              className="text-danger cursorPointer"
              onClick={() => handleDelete(index)}
            />
          </div>
          <a
            href={src}
            target={ext === "pdf" ? "_blanks" : "_self"}
            key={index}
          >
            <img
              src={
                ext === "pdf"
                  ? PDF
                  : ext === "document" ||
                    ext === "docx" ||
                    ext === "doc" ||
                    ext === "dot" ||
                    ext === "dotx" ||
                    ext === "docm" ||
                    ext === "csv" ||
                    ext === "dotm"
                    ? CSV
                    : ext === "ppt" ||
                      ext === "pptx" ||
                      ext === "presentation" ||
                      ext === "ppt" ||
                      ext === "pptx" ||
                      ext === "pot" ||
                      ext === "pps" ||
                      ext === "ppa" ||
                      ext === "potx" ||
                      ext === "ppsx" ||
                      ext === "ppam" ||
                      ext === "pptm" ||
                      ext === "potm" ||
                      ext === "ppsm" ||
                      ext === "mdb"
                      ? ppt
                      : Excel
              }
              style={{ height: 42, width: 46 }}
            />
          </a>

          <small className="text-truncate">{filename}</small>
        </div>
      );
    } else if (
      ext === "png" ||
      ext === "jpg" ||
      ext === "jpeg" ||
      ext === "svg" ||
      ext === "WebP"
    ) {
      return (
        <div
          key={index}
          className="flex-column d-flex mt-1 ml-1 mr-1  text-nowrap"
          style={{ height: 80, width: 70 }}
        >
          <div className="d-flex justify-content-center">
            <TiDelete
              size={20}
              className="text-danger cursorPointer"
              onClick={() => handleDelete(index)}
            />
          </div>
          <a href={src} target="_blank">
            <img src={Image} style={{ height: 42, width: 46 }} />
          </a>

          <small className="text-truncate">{filename}</small>
        </div>
      );
    } else {
      return (
        <div
          key={index}
          className="flex-column d-flex mt-1 ml-1 mr-1  text-nowrap"
          style={{ height: 80, width: 70 }}
        >
          <div className="d-flex justify-content-center">
            <TiDelete
              size={20}
              className="text-danger cursorPointer"
              onClick={() => handleDelete(index)}
            />
          </div>
          <a href={src} target="_blank">
            <img src={file} style={{ height: 42, width: 46 }} />
          </a>
          <small className="text-truncate">{src}</small>
        </div>
      );
    }
  });

  return (
    <Fragment>
      <Dropzone
        onDrop={(acceptedFiles) => {
          setLoading(true);
          // uploadFileS3(acceptedFiles[0], callBackS3);
          convertToBase64(acceptedFiles);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()} className="Attachment">
              <input {...getInputProps()} multiple={false} test="upload file test" />

              <p className="text-center text-secondary">
                <img src={DragNDrop} size={20} alt="DragNDrop" />
              </p>

              <p className="text-center">
                <FormattedMessage
                  id={"Drop files here or browse"}
                  defaultMessage="Drop files here or browse"
                />
              </p>
            </div>

            <aside className="d-flex ex1 flex-row ">{thumbs}</aside>
          </section>
        )}
      </Dropzone>
      {loading && (
        <div className="mt-1">
          <Spinner color="primary" size="sm" />
          <span className="ml-50">Uploading...</span>
        </div>
      )}
    </Fragment>
  );
}

export default FileUploader;
