import React, { Fragment } from "react";

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import "@styles/base/plugins/extensions/ext-component-toastr.scss";
import FormGroup from "reactstrap/lib/FormGroup";
import CreatableSelect from "react-select/creatable";

import { checkPermission } from "@src/views/Helper/component/hasPermissions";

import { connect, useSelector, useDispatch } from "react-redux";

import { FormattedMessage } from "react-intl";

import FileUploader from "@src/views/Helper/component/FileUploader";
import { observer } from "mobx-react-lite";
import { toggleModalPop } from "@store/actions/modal";
import TicketMigrationModal from "../TicketsMigration/List/components/TicketMigrationModal";

const AppModal = observer((props) => {
  const red = useSelector((state) => state.modal);

  const visibility = red.show;

  const dispatch = useDispatch();

  const toggleModal = () => {
    dispatch(toggleModalPop());
  };

  return (
    <Fragment>
      <Modal isOpen={visibility} toggle={() => toggleModal()}>
          {red.modalName === "MIGRATION_MODAL" ? (
            <TicketMigrationModal status={red.status} />
          ) : (
            ""
          )}
      </Modal>
    </Fragment>
  );
});

export default AppModal;
 